// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-recruite-js": () => import("./../../../src/pages/recruite.js" /* webpackChunkName: "component---src-pages-recruite-js" */),
  "component---src-templates-change-management-js": () => import("./../../../src/templates/change-management.js" /* webpackChunkName: "component---src-templates-change-management-js" */),
  "component---src-templates-cloud-analytics-js": () => import("./../../../src/templates/cloud-analytics.js" /* webpackChunkName: "component---src-templates-cloud-analytics-js" */),
  "component---src-templates-cloud-integration-js": () => import("./../../../src/templates/cloud-integration.js" /* webpackChunkName: "component---src-templates-cloud-integration-js" */),
  "component---src-templates-cloud-strategy-js": () => import("./../../../src/templates/cloud-strategy.js" /* webpackChunkName: "component---src-templates-cloud-strategy-js" */),
  "component---src-templates-design-build-and-operate-js": () => import("./../../../src/templates/design-build-and-operate.js" /* webpackChunkName: "component---src-templates-design-build-and-operate-js" */),
  "component---src-templates-digital-innovation-js": () => import("./../../../src/templates/digital-innovation.js" /* webpackChunkName: "component---src-templates-digital-innovation-js" */),
  "component---src-templates-product-delivery-process-js": () => import("./../../../src/templates/product-delivery-process.js" /* webpackChunkName: "component---src-templates-product-delivery-process-js" */)
}

